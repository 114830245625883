
import { mapState } from "vuex"
import Modal from "@/components/base/modal/Modal"
import ValidationResponse from "@/components/base/api/ValidationResponse"
import Button from "@/components/base/Button"
import businessService from "@/services/business.service"
import rules from "@/mixins/rules"

export default {
    name: "ReplicateJobDialog",
    components: { ValidationResponse, Modal, Button },
    mixins: [rules],
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        jobSlug: {
            type: String,
            default: null,
        },
        jobType: {
            type: Number,
            default: null,
        },
        businessSlug: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        businessSites: [],
        businessSiteId: null,
        systemJobsID: null,
        jobName: null,
        validationData: null,
        isLoading: false,
    }),
    computed: {
        ...mapState("business", ["systemJobs"]),
        model: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit("input", newVal)
            },
        },
        businessSitesWithCity() {
            return this.businessSites.map((site) => ({
                ...site,
                name: `${site.name} (${site.primary_address.city} - ${site.primary_address.street})`,
            }))
        },
    },
    watch: {
        model(newVal) {
            if (newVal) {
                this.$store.dispatch("business/getSystemJobs")
                this.getBusinessSites()
            }
        },
    },
    methods: {
        async getBusinessSites() {
            if (this.businessSites.length > 0) return
            if (this.$can.permission("manage_business_sites")) {
                this.isLoading = true
                this.businessSites = await businessService
                    .getBusinessSites(this.businessSlug)
                    .catch(() => [])
                this.isLoading = false
            }
        },
        replicate() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.$axios
                .post("/api/job/replicate", {
                    job_slug: this.jobSlug,
                    system_job_id: this.systemJobsID
                        ? this.systemJobsID
                        : undefined,
                    name: this.jobName ? this.jobName : undefined,
                    business_site_id: this.businessSiteId,
                })
                .then(() => {
                    this.$store.dispatch("business/loadJobs", this.businessSlug)
                    this.$emit("close-replicate-dialog")
                })
                .catch((error) => {
                    this.validationData = error.response.data
                })
        },
    },
}
